import styled from 'styled-components';

const BigQuoteSectionWrapper = styled.section`
    padding: 120px 0 100px 0;
    position: relative;
    overflow: hidden;
    .section__particle{
        position: absolute;
        &.one{
            right: 9%;
            top: 19%;
            height: 34px;
            width: 40px;
            transform: rotate(-45deg);
        }
        &.two{
            width: 150px;
            top: auto;
            bottom: 80px;
            left: -22px;
        }
    }
   
    .bigquote-thumb{
        width: 100%;
        border-radius: 50%;
        display: inline-block;
    }
    .bigquote-text.quote{
        position: absolute;
        top: 30px;
        left: 0px;
        font-size: 184px;
    }
    .bigquote-text.text-one{
        font-size: 24px;
        padding-left: 7vw;
        color: ${props => props.theme.headingColor};
        font-weight: 400;
        margin-bottom: 28px;
    }
    
    .bigquote-text.text-two{
        font-weight: 300;
        padding-left: 7vw;
        margin-bottom: 80px;
        span{
            font-size: 26px;
        }
    }
    .section-title{
        h2{
            margin-bottom: 38px;
        }
    }

    @media only screen and (max-width: 1400px) {
        .bigquote-thumb{
            transform: translateX(0px);
        }
        .section__particle{
            &.one{
                top: 9%;
            }
            &.two{
                bottom: -92px;
                left: -38px;
            }
        }
     }
     @media only screen and (max-width: 1024px) {
        .section__particle{
            &.one {
                top: 0%;
            }
        }
    }
    @media only screen and (max-width: 912px) {
        padding: 100px 0 60px 0;
        .section-title h2{
            margin-bottom: 20px;
        }
        .bigquote-text.text-two{
            margin-bottom: 30px;
        }
    }
    @media only screen and (max-width: 760px) {
        padding: 100px 0 60px 0;
       .bigquote-thumb{
           margin-bottom: 70px;
       }
    }
    @media only screen and (max-width: 568px) {
        .section-title,
        .bigquote-content-wrap{
            text-align: center;
        }
        .section-title{
            img{
                display: none;
            }
            h4{
                width: 100%;
            }
        }
        .bigquote-text.text-one, .bigquote-text.text-one{
            padding-left: 0px;
        }
    }
    @media only screen and (max-width: 480px) {
        padding: 80px 0 60px 0;
    }
`
export default BigQuoteSectionWrapper;