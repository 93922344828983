import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import VintageBox from "common/src/reusecore/VintageBox";

import imgBigQuote from "common/src/assets/images/app/bigquote.jpg";
import particle1 from "common/src/assets/images/app/particle/05.png";
import particle2 from "common/src/assets/images/app/particle/06.png";

import BigQuoteSectionWrapper from "./bigquote.style";
import { Trans } from 'common/src/reusecore/Trans';

const BigQuote = () => {
  return (
    <BigQuoteSectionWrapper id="bigquote">
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <Container fullWidthSM>
        <Row Vcenter={true}>
          <Col xs={12} sm={6} className="bigquote-content-wrap">
            <span className="bigquote-text quote">&ldquo;</span>
            <p className="bigquote-text text-one">
              <Trans i18nKey="bigquote.text" />
            </p>
            <p className="bigquote-text text-two">
              <span><Trans i18nKey="bigquote.author" /></span>
              <br/>
              &nbsp;&mdash;&nbsp;
              <Trans i18nKey="bigquote.position" />
            </p>
          </Col>
          <Col xs={12} sm={6}>
            <img
              className="bigquote-thumb"
              src={imgBigQuote}
            />
          </Col>
        </Row>
      </Container>
    </BigQuoteSectionWrapper>
  );
};

export default BigQuote;
