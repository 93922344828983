import styled from 'styled-components';

import imgHero from 'common/src/assets/images/app/hero/hero.png';

const BannerSectionWrapper = styled.section`
    padding: 80px 0 120px 0;
    margin: 80px 0 0 0;
    background: url(${imgHero}) no-repeat;
    background-size: 70%;
    background-position: center right -60%;
    .privacy {
        font-size: 12px;
    }

    .banner-wrapper{
        width: 55%;
    }
    .banner-email{
        max-width: 550px;
    }
    .subtext {
        padding-right: 30px;
    }
    p{
        font-size: 16px;
        font-weight: 300; 
        margin: 0 0 70px 0;
    }
    h4{
        margin-bottom: 8px;
        font-size: 18px;
        color: ${props => props.theme.secondaryColor};
        text-transform: uppercase;
    }
    h3 {
        margin-bottom: 18px;
    }
    form > div{
        height: 60px;
    }
    form{
        text-align: center;
        text-align: center;
        background: rgba(244, 244, 244, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        border-radius: 10px;
    }
    input{
        display: block;
        padding: 15px;
        width: 100%;
        height: 60px;
        font-size: 16px;
        border-radius: 5px;
        margin: 0 auto 0px auto;
        box-shadow: none;
        transition: 450ms all;
        border: 1px solid ${props => props.theme.secondaryColor};
        &:hover,&:focus{
            border-color: ${props => props.theme.primaryColor};
        }
    }
    button{
        transition: 450ms all;
        background: #FFEBEC;
        color: #FB7B81;
        font-size: 16px;
        height: 58px;
        &:hover{
            background: #FB7B81;
            color: #fff;
        }
    }
    .section-title{
        h1{
            font-weight: 300;
            font-size: 60px;
            line-height: 75px;
            margin: 0 0 38px 0; 
            strong{
                font-weight: 600;
            }
        }
    }
    .banner-btn.one{
        background: ${props => props.theme.primaryColor};
        color: ${props => props.theme.white};
        margin-left: 5px;
        width: auto;
        &:hover{
            background: ${props => props.theme.secondaryColor};
            color: ${props => props.theme.white};
        }
    }
    .banner-btn.two{
        background: #FFEBEC;
        color: #FB7B81;
        &:hover{
            background: #FB7B81;
            color: #fff;
        }
    }
    .banner-btn+.banner-btn{
        margin-left: 30px;
    }
    
    @media only screen and (max-width: 1200px) {
        .section-title h1{
            font-size: 40px;
            line-height: 42px;
        }
    }
    @media only screen and (max-width: 912px) {
        margin: 30px 0 0 0;
        background-size: 75%;
        background-position: center right -82%;
        padding: 120px 0 60px 0;
        .section-title{
            h1{
                font-size: 32px;
                line-height: 42px;
                margin: 0 0 25px 0;
            }
            h4{
                font-size: 16px;
            }
        }
        p{ 
            margin: 0 0 30px 0;
        }
        .banner-btn{
            min-width: 140px;
        }
        .banner-btn+.banner-btn{
            margin-left: 17px;
        }
     }
     @media only screen and (max-width: 760px) {
        padding: 130px 0 60px 0;
     }
     @media only screen and (max-width: 568px) {
        background-size: 100%;
        background-position: center calc(50% - 50px);
        .banner-btn{
            min-width: 160px;
        }
        .banner-wrapper{
            width: 100%;
        }
        .section-title{
            padding-bottom: 320px;
        }
    }
     @media only screen and (max-width: 480px) {
        padding: 120px 0 100px 0;
        .section-title{
            h1{
                font-size: 35px;
                line-height: 50px;
            }
        }
         .vintage-box{
             &:before{
                 content: none;
             }
         }
     }
     @media only screen and (max-width: 380px) {
       
        .section-title{
            h1{
                font-size: 30px;
                line-height: 46px;
                margin: 0 0 30px 0;
            }
            h4{
                font-size: 15px;
            }
        }
        .banner-btn{
            font-size: 14px;
            min-width: 127px;
            padding: 14px 12px; 
        }
        .banner-btn+.banner-btn{
            margin-left: 15px;
        }
     }
     @media only screen and (max-width: 330px) {
        .section-title{
            h1{
                font-size: 23px;
                line-height: 35px;
            }
        }
     }
     
`

export default BannerSectionWrapper;
