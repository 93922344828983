import React from "react";
import {ThemeProvider} from 'styled-components';
import ReactPixel from 'react-facebook-pixel';
import { install } from 'ga-gtag';

import Layout from "../components/layout" 
import SEO from "../components/seo" 

import Navigation from 'common/src/sections-gatsby/Navigation'; 
import Banner from 'common/src/sections-gatsby/Banner-default'; 
import About from 'common/src/sections-gatsby/About';
import Features from 'common/src/sections-gatsby/Features';
import BigQuote from 'common/src/sections-gatsby/BigQuote';
import Testimonial from 'common/src/sections-gatsby/Testimonial';
import Contact from 'common/src/sections-gatsby/Contact';
import Footer from 'common/src/sections-gatsby/Footer';
import GdprBanner from 'common/src/sections-gatsby/GdprBanner';
import ParticleComponent from "common/src/reusecore/Particle";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { GlobalStyle } from 'common/src/sections-gatsby/app.style';
import theme from 'common/src/theme/app/themeStyles';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from 'common/src/assets/data/translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    resources: translations,
    interpolation: {
      escapeValue: false,
    },
  });

class IndexPage extends React.Component {
  componentDidMount() {
    const pixelOptions = {
      autoConfig: true,
    };

    ReactPixel.init('1144495309261172', {}, pixelOptions);
    ReactPixel.pageView();

    install('G-18TGXMHYGQ');
  }

  render() {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfPnPwUAAAAALmsYoJ2rqC_OJ2FnEz8CK9Ppfcr"
        language="en_US"
        useRecaptchaNet={true}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Layout>
            <SEO title="be.cash - Be your own bank" />
            <Navigation />
            <ParticleComponent />
            <Banner />
            <About />
            <BigQuote />
            <Features />
            <Testimonial />
            <Contact />
            <Footer />
            <GdprBanner />
          </Layout>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    )
  }
}

export default IndexPage
