import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import VintageBox from "common/src/reusecore/VintageBox";

import i18next from 'i18next';
import { Trans, useTranslation } from 'common/src/reusecore/Trans';
import { track } from 'common/src/reusecore/Tracking';
import { FaPlay, FaDownload, FaBookOpen } from "react-icons/fa";

import BannerSectionWrapper from "./banner.style";

const BannerDefault = () => {
  const { t } = useTranslation();
  return (
    <BannerSectionWrapper>
      <Container fullWidthSM>
        <div className="banner-wrapper">
          <SectionTitle
            className="section-title"
            leftAlign={true}
            UniWidth="100%"
          >
            <h4>
              <Trans i18nKey="banner.quote.text" />
              &mdash;
              <a href="https://twitter.com/vinarmani/status/1193920092481785857" target="_blank">
                <Trans i18nKey="banner.quote.author" />
              </a>
            </h4>
            <h1>
              <Trans i18nKey="banner.title" />
            </h1>
          </SectionTitle>
          <p className="subtext">
            <Trans i18nKey="banner.subtext" />
          </p>
        </div>
        <div className="banner-email">
          <VintageBox right={true} vintageOne={true}>
            <h3><Trans i18nKey="subscribe.announcement.0" /> &mdash; <Trans i18nKey="subscribe.announcement.1" /></h3>
            <form method="post" action="/subscribe" id="form-banner">
              <input type="email" name="email" placeholder={t('subscribe.enter_email')} id="email-banner" />
              <input type="hidden" name="captcha" id="captcha-banner" />
              <input type="hidden" name="lng" id="lng-banner" />
              <Button
                className="banner-btn one"
                onClick={e => {
                  e.preventDefault();
                  const email = document.getElementById('email-banner').value;
                  track('subscribeBanner', {email});
                  if (email.length == 0)
                    return;
                  grecaptcha.ready(function() {
                    grecaptcha.execute('6LfPnPwUAAAAALmsYoJ2rqC_OJ2FnEz8CK9Ppfcr', {action: 'submit'}).then(function(token) {
                      document.getElementById('captcha-banner').value = token;
                      document.getElementById('lng-banner').value = i18next.language;
                      document.getElementById('form-banner').submit();
                    });
                  });
                }}
              >
                <Trans i18nKey="subscribe.action" />
              </Button>
            </form>
            <span className="privacy">
              <Trans i18nKey="subscribe.disclaimer">
                By clicking subscribe you agree to our <a href="/privacy">privacy policy</a>.
              </Trans>
            </span>
          </VintageBox>
        </div>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerDefault;
